import React from 'react'

import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'

import styles from './Contact.module.scss'

export const Contact: React.FC = () => {
  return (
    <div className={styles.container}>
      <Box component="main" sx={{ p: 3 }}>
        <Toolbar />
          <Typography>
            Shoot us an email at
          </Typography>
          <a className={styles.mailtoLink} href="mailto:getyushu@gmail.com">getyushu@gmail.com</a>
      </Box>
    </div>
  )
}
