import React from 'react'
import { Link } from "react-router-dom"

import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'

import kangaroo from '../../../assets/kangaroo-2.png'
import styles from './Main.module.scss'

export const Main: React.FC = () => {
  return (
    <Box component="main" sx={{ p: 3 }}>
      <Toolbar />
      <Typography>
        <div className={styles.hero}>
          <div className={styles.heroContent}>
            <h3>
              Our trainers work with your team using evidence-based methods to elevate their health and well being
            </h3>
            <Link to='/contact' style={{ textDecoration: 'none' }}>
              <button className={styles.mainCta}>
                Start today
              </button>
            </Link>
          </div>
          <div className={styles.kangaroo}>
            <img src={kangaroo} alt="kangaroo" />
          </div>
        </div>
      </Typography>
    </Box>
  )
}
