import * as React from 'react'
import {
  Routes,
  Route,
  Link
} from "react-router-dom"
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import CssBaseline from '@mui/material/CssBaseline'
import Divider from '@mui/material/Divider'
import Drawer from '@mui/material/Drawer'
import IconButton from '@mui/material/IconButton'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'
// import MenuIcon from '@mui/icons-material/Menu'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import logo from '../../../logo.svg'

import { Main } from './Main'

import styles from './Landing.module.scss'
import { Contact } from './Contact'



interface Props {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window?: () => Window
}

const drawerWidth = 240

const navItems = [
  { label: 'Home', to: '/', cta: false },
  // { label: 'About', to: '/about', cta: false },
  { label: 'Contact', to: '/contact', cta: true },
]

export default function DrawerAppBar(props: Props) {
  const { window } = props
  const [mobileOpen, setMobileOpen] = React.useState(false)

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState)
  }

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
      <div className={styles.icon}>
        <img src={logo} alt='logo' />
        ushu
      </div>
      <Divider />
      <List>
        {navItems.map((item) => {
          return (
            <ListItem key={item.label} disablePadding>
              <Link to={item.to} style={{ textDecoration: 'none' }}>
                <ListItemButton sx={{ textAlign: 'center' }}>
                  <ListItemText primary={item.label} sx={{ color: '#FE8801', fontFamily: 'system-ui', fontWeight: 500 }} />
                </ListItemButton>
              </Link>
            </ListItem>
          )
        })}
      </List>
    </Box>
  )

  const container = window !== undefined ? () => window().document.body : undefined

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar component="nav">
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <div className={styles.icon}>
              <img src={logo} alt='logo' />
              ushu
            </div>
          </IconButton>
          <Typography
            variant="h6"
            component="div"
            sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }}
          >
            <div className={styles.icon}>
              <img src={logo} alt='logo' />
              ushu
            </div>
          </Typography>
          <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
            {navItems.map((item) => (
              <Link to={item.to} style={{ textDecoration: 'none' }}>
                <Button key={item.label} style={{ fontWeight: 700 }}>
                  {item.label}
                </Button>
              </Link>
            ))}
          </Box>
        </Toolbar>
      </AppBar>
      <nav>
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
      </nav>
      <Routes>
        <Route path="/" element={<Main />}/>
        <Route path="/contact" element={<Contact />}/>
      </Routes>
      <div className={styles.bottomIcon}>
        <img src={logo} alt='logo' />
      </div>
    </Box>
  )
}
