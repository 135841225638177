import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      light: '#AADCFE', // Light Blue
      main: '#AADCFE',  // Light Blue as main
      dark: '#0074cc',  // A deeper shade of blue derived from the primary
      contrastText: '#FE8801',
    },
    secondary: {
      main: '#FE8801',  // Orange
      contrastText: '#ffffff', // White text for contrast
    },
    background: {
      default: '#ffffff',
      paper: '#003459',   // Prussian Blue
    },
    text: {
      primary: '#FE8801', // Default text color
      secondary: '#8367c7', // Amethyst for secondary text
    },
    action: {
      active: '#FE8801', // Orange for active states
      hover: '#AADCFE',  // Light blue for hover states
      selected: '#003459', // Prussian Blue for selected states
    },
  },
  typography: {
    fontFamily: 'Roboto, Arial, sans-serif',
    h1: { color: '#AADCFE' }, // Light Blue for headers
    h2: { color: '#FE8801' }, // Orange for headers
    body1: { color: '#00171f' },
    body2: { color: '#8367c7' }, // Secondary body text (Amethyst)
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          borderRadius: 8,
          color: '#FE8801',
        },
        containedPrimary: {
          backgroundColor: '#FE8801',
          color: '#ffffff',
          '&:hover': {
            backgroundColor: '#d46c01',
          },
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        root: {
          color: '#FE8801',
        },
        paper: {
          backgroundColor: '#AADCFE', // Set the background color of the drawer
          color: '#FE8801',           // Set the text color of the drawer
        },
      },
    },
  },
});

export { theme };
