import React from 'react';
// import { Workout } from './components/Workout/Workout'
import Landing from './components/pages/Landing/Landing'
// import { Day } from './interfaces/interface'
// import logo from './logo.svg'
import { ThemeProvider } from '@mui/material/styles'
import { theme } from './muiThemes'

import { BrowserRouter as Router } from "react-router-dom"

// import styles from "./App.module.scss"

function App() {

  /*
  return (
    <div>
      <img src={logo} className={styles.appLogo} alt="logo" />
      <Workout day={Day.Friday}/>
    </div>
  )

  */

  return (
    <Router>
      <ThemeProvider theme={theme}>
        <Landing />
      </ThemeProvider>
    </Router>
  )

}

export default App;
